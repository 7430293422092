<template>
  <div class="environment-empty d-flex align-center justify-center py-6">
    <div class="environment-empty__container d-flex flex-column align-center">
      <img
        class="environment-empty__illustration mb-6"
        :width="175"
        :src="$static.illustration"
        :alt="$t('environment.component.empty')">

      <template v-if="hasCreatePermissions">
        <h3 class="mb-1">
          {{ $t('environment.component.empty') }}
        </h3>
        <p class="mb-0">
          {{ $t('environment.component.description') }}
        </p>
        <CyButton
          icon="add_circle_outline"
          theme="secondary"
          variant="primary"
          class="my-6"
          :to="{
            name: 'newEnvComponent',
            params: { envCanonical },
          }">
          {{ $t('environment.component.add') }}
        </CyButton>
        <p class="mb-8">
          <!-- TODO: FE#7637 write documentation and add links to code base. -->
          <a
            :href="$docLinks.project.components"
            class="cy-link"
            target="_blank">
            {{ $t('environment.component.learnMore') }}
            <v-icon small>
              open_in_new
            </v-icon>
          </a>
        </p>
      </template>

      <template v-else>
        <h3 class="mb-1">
          {{ $t('environment.component.empty') }}
        </h3>

        <p class="mb-0">
          {{ $t('noPermissions') }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { components } from '@/assets/images'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageEnvironmentEmpty',
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical } = this
    return constructBreadcrumb(this.$options.name, envCanonical, [
      {
        label: this.$t('Environments'),
        name: 'projectOverview',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  props: {
    projectCanonical: {
      type: String,
      required: true,
    },
    envCanonical: {
      type: String,
      required: true,
    },
  },
  computed: {
    $static: () => ({
      illustration: components.empty,
    }),
    ...mapGetters('organization/project', [
      'envComponents',
    ]),
    hasCreatePermissions () {
      return this.$cycloid.permissions.canDisplay('UpdateProject')
    },
    hasEnvComponents () {
      return !_.isEmpty(this.envComponents(this.envCanonical))
    },
  },
  watch: {
    envCanonical: {
      handler: 'checkEnvComponents',
      immediate: true,
    },
  },
  methods: {
    checkEnvComponents () {
      if (this.hasEnvComponents) {
        this.$router.replace({
          name: 'environmentOverview',
          params: { projectCanonical: this.projectCanonical, envCanonical: this.envCanonical },
        })
      }
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:Environments',
        noPermissions: 'You do not have sufficient permissions to create a component.',
      },
      es: {
        title: '@:Environments',
        noPermissions: 'No tiene permisos suficientes para crear un componente.',
      },
      fr: {
        title: '@:Environments',
        noPermissions: `Vous n'avez pas les autorisations suffisantes pour créer un composant.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.environment-empty {
  &__container {
    max-width: 500px;

    h3,
    p {
      text-align: center;
    }

    .cy-btn {
      margin-right: auto;
      margin-left: auto;
    }

    .cy-link {
      .v-icon {
        color: cy-get-color("grey", "dark-1");
      }
    }
  }

  &__illustration {
    margin-top: 128px;
  }
}
</style>
